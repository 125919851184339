const API = {
    BASE_URL:process.env.NODE_ENV === 'development'? "https://backend.api.testing.farmaze.com/":  process.env.REACT_APP_BACKEND_API_BASE_URL,
    BASE_URL_ANALYTICS:process.env.NODE_ENV === 'development'? "https://analytics.api.testing.farmaze.com/":  process.env.REACT_APP_ANALYTICS_API_BASE_URL,
    
    // Update each endpoint with BASE_URL prefix
    login: `api/v1/auth/login`,
    products: `api/v1/products/`,
    placeOrder: `api/v1/b2bclients/orders`,
    orderList: `api/v1/b2bclients/orders`,
    orderListIds: `api/v1/b2bclients/orders/list/ids`,
    invoices: `api/v1/b2bclients/invoices`,
    clientDetails:"api/v1/b2bclients/details",
    
    // // Analytics endpoints also use the BASE_URL_ANALYTICS
    // analyticsEndpoint1: `${process.env.REACT_APP_ANALYTICS_API_BASE_URL}your_analytics_endpoint_1`,
    // analyticsEndpoint2: `${process.env.REACT_APP_ANALYTICS_API_BASE_URL}your_analytics_endpoint_2`,
    // Add more analytics endpoints as needed
};

export { API };
